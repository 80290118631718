:root {
  --finBlue: rgb(28, 132, 203);
}

body {
  background-color: rgb(234, 234, 234) !important;
}

.ui.inverted.top.fixed.menu {
  background-color: var(--finBlue);
}

/*home page styles*/

body, h1, h2, h3, a, div {  
  font-family: 'Arial' !important;
}

.masthead {
  display: flex;
  align-items: center;
  /*background-color: rgb(28, 132, 203);*/
  height: 70vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.masthead p {
  font-size: large;
}

h1 {
  color: var(--finBlue) !important;
}

.homeHeader {
    color: var(--finBlue) !important;
}

.finIcon {
  color: var(--finBlue) !important;
}

a.ui.card {
  height: 195px;
}

.centeredImage {
  display: table-cell;
  vertical-align: middle;
}

.boldGridColumn {
    font-weight: bold;
}

.highlightTable {
    overflow-x:scroll;
}

.registerlink {
    font-size: 20px;
    color: red;
}

/*end home page styles*/